import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { SagaThemeProvider } from 'saga-library/src'
import { ConcurrencysProvider } from "./providers/ConcurrencyConflictProvider";
import { ConfigurationProvider } from "./providers/ConfigurationProvider";
import { MQAuthProvider } from "./providers/MQAuthProvider";
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <SagaThemeProvider>
      <ConfigurationProvider>
        <MQAuthProvider>
          <ConcurrencysProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ConcurrencysProvider>
        </MQAuthProvider>
      </ConfigurationProvider>
    </SagaThemeProvider>
  </React.StrictMode>
)
