import React from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { IconButton } from "../Button";

const RemoveButton = ({ onClick, sx = {}, disabled=false, dataTestId }) => {
  return (
    <IconButton
      dataTestId={`${dataTestId}-remove-button`}
      onClick={onClick}
      sx={{
        width: '40px',
        height: '40px',
        ...sx
      }}
      disabled={disabled}
      icon={<DeleteOutlineIcon />}
    />
  )
}

export default RemoveButton
