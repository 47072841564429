import React, { useState, useMemo, useCallback, ReactNode } from "react";
import { Snackbar } from '../index'
import {AlertColor} from '@mui/material/Alert'

type AlertsType = {
  showSuccessAlert: (message: string) => void
  showErrorAlert: (message: string, Action?: React.ReactNode) => void
  showWarningAlert: (message: string) => void
}

const Alerts = React.createContext<AlertsType>({
  showSuccessAlert: (msg) => void msg,
  showErrorAlert: (msg, Action) => void msg,
  showWarningAlert: (msg) => void msg
})

export interface SnackbarMessage {
  id: number
  message: string
  type: AlertColor
  Action?: ReactNode
}

export const AlertsProvider = ({ children }) => {
  const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>([]);
  const [snackbar, setSnackbar] = useState<{ snackbarMessage: SnackbarMessage, open: boolean}>({
    open: false,
    snackbarMessage: {
      message: '',
      Action: undefined,
      id: 0,
      type: "success"
    }
  })

  React.useEffect(() => {
    if (snackPack.length && !snackbar.snackbarMessage.message) {
      // Set a new snack when we don't have an active one
      setSnackbar({ open: true, snackbarMessage: { id: snackPack[0].id, message: snackPack[0].message, type: snackPack[0].type, Action: snackPack[0].Action } })
      setSnackPack((prev) => prev.slice(1));
    } else if (snackPack.length && snackbar.snackbarMessage.message && snackbar.open) {
      // close an active snack when a new one is added
      setSnackbar({ open: false, snackbarMessage: { id: 0, message: '', type: "success"} })
    }
  }, [snackPack, snackbar]);

  const showSuccessAlert = useCallback((message) => {
    setSnackPack((prev) => [...prev, { message, id: new Date().getTime(), type: "success" }]);
  }, [])

  const showErrorAlert = useCallback((message, Action) => {
    setSnackPack((prev) => [...prev, { message, id: new Date().getTime(), type: "error", Action: Action}]);
  }, [])

  const showWarningAlert = useCallback( (message) => {
    setSnackPack((prev) => [...prev, { message, id: new Date().getTime(), type: "warning"}]);
  }, [])

  const value = useMemo(
    () => ({ showErrorAlert, showSuccessAlert, showWarningAlert }),
    [showErrorAlert, showSuccessAlert, showWarningAlert]
  )

  return (
    <Alerts.Provider value={value}>
      {children}
      <Snackbar
        dataTestId={"snackbar"}
        id={snackbar ? snackbar.snackbarMessage.id : 0}
        message={snackbar.snackbarMessage.message}
        open={snackbar.open}
        setSnackbar={setSnackbar}
        type={snackbar.snackbarMessage.type}
        action={snackbar.snackbarMessage.Action}
      />
    </Alerts.Provider>
  )
}

export const useAlerts = () => {
  return React.useContext(Alerts)
}
