import React from "react";
import TableCell from "@mui/material/TableCell";


export interface DraggableCellProps {
  data?: string
  styles?: object
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit'
  children?: React.ReactNode
  onClick?: () => void
  isDragOccurring?: boolean
  lastCol?: boolean
}
type TableCellSnapshot = {
  width: number,
  height: number,
}

export class DraggableTableListCell extends React.Component<DraggableCellProps> {
  ref?: HTMLElement | null
  setRef = (ref: HTMLElement | null) => {
    this.ref = ref;
  }

  getSnapshotBeforeUpdate(prevProps: DraggableCellProps): TableCellSnapshot | null {
    const isDragStarting = this.props.isDragOccurring && !prevProps.isDragOccurring
    if (!this.ref || !isDragStarting) {
      return null
    }
    const { width, height } = this.ref.getBoundingClientRect()
    return {
      width,
      height,
    }
  }

  componentDidUpdate(
    prevProps: DraggableCellProps,
    prevState: object,
    snapshot?: TableCellSnapshot,
  ) {
    const ref: HTMLElement | null | undefined = this.ref;
    if (!ref) {
      return;
    }

    if (snapshot) {
      if (Number(ref.style.width) === snapshot.width) {
        return
      }
      ref.style.width = `${snapshot.width}px`
      ref.style.height = `${snapshot.height}px`
      return
    }

    if (this.props.isDragOccurring) {
      return
    }

    // inline styles not applied
    if (ref.style.width == null) {
      return
    }

    // no snapshot and drag is finished - clear the inline styles
    ref.style.removeProperty('height')
    ref.style.removeProperty('width')
  }

  render(){
    const { data, styles, align, children, onClick, lastCol } = this.props
    return (
      <TableCell
        ref={this.setRef}
        sx={{
          boxSizing: 'border-box',
          fontWeight: '500',
          fontSize: '14px',
          padding: '4px',
          lineHeight: '42px',
          whiteSpace: 'nowrap',
          paddingLeft: '8px',
          paddingRight: '8px',
          width: lastCol ? '100%' : 'auto',
          ...styles,
        }}
        align={align}
        onClick={onClick}
      >
        {children ? children : data}
      </TableCell>
    )
  }

}