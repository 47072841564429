import { gql } from "@apollo/client";

export const LOG_PRINT_OPERATIONS = gql`
  mutation LogPrintOperations($tenantId: ID!, $objectType: UserActivityObjectType!, $objectId: ID!, $patientId: ID) {
        tenant(id: $tenantId) {
            activityTracking {
                logPrintActivity(objectType: $objectType, id: $objectId, patientId: $patientId) 
            }
        }
    }
`