import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { AccountCircle } from "@mui/icons-material";
import { Menu, MenuItem, MenuListProps } from "@mui/material";
import * as React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { MouseEventHandler, useEffect, useState } from "react";
import { LogoWhiteOnGreen } from "./Logo";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AccountInfo } from "@azure/msal-common";
import { useMQAuthProvider } from "../providers/MQAuthProvider";

const pages = ["Clinics", "Forms"];

export default function SupportAppBar() {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { instance } = useMQAuthProvider()
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const [userDisplayName, setUserDisplayName] = useState<string>("");

  async function handleLogout() {
    handleCloseUserMenu();

    const logoutRequest = {
      account: accounts[0],
      mainWindowRedirectUri: "/",
    };

    await instance.logoutPopup(logoutRequest);
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handlePageNavigation = (event, page) => {
    navigate(`/${page.toLowerCase()}`);
  };

  const logoClick: MouseEventHandler = () => {
    navigate(`/`);
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    const getUserDetails = async () => {
      const request: {scopes: string[], account: AccountInfo} = {
        scopes: ["User.Read"],
        account: accounts[0]
      };

      let token: string;
      try {
        const response = await instance.acquireTokenSilent(request);
        token = response.accessToken;
      } catch {
        const response = await instance.acquireTokenPopup(request);
        token = response.accessToken;
      }

      axios.get("https://graph.microsoft.com/v1.0/me",
        {
          headers: {
            ...axios.defaults.headers.common,
            Authorization: token ? `Bearer ${token}` : null
          }
        })
        .then(function(response) {
          setUserDisplayName(response.data.displayName);
        })
        .catch(function(error) {
          console.log("error", error);
        });
    };

    getUserDetails();
  }, [accounts, isAuthenticated, instance]);

  return <AppBar
    sx={{
      backgroundColor: (theme) => theme.palette.primary.main
    }}>
    <Toolbar
      sx={{
        pr: "24px",
        justifyContent: "space-between"
      }}
    >
      <LogoWhiteOnGreen onClick={logoClick} />
      <Box sx={{ flexGrow: 1, display: { md: "flex" }, pl: 4 }}>
        {pages.map((page, index) => (
          <Button
            data-testid={`supportAppBar-page-button-${index}`}
            name={page}
            key={page}
            onClick={(e) => handlePageNavigation(e, page)}
            sx={{ my: 2, color: "white", display: "block", textTransform: "none" }}
          >
            {page}
          </Button>
        ))}
      </Box>
      <Box data-testid={"supportAppBar-user"} sx={{ flexGrow: 0 }}>
        <IconButton
          data-testid={"supportAppBar-user-button"}
          edge="end"
          aria-label="account of current user"
          color="inherit"
          onClick={handleOpenUserMenu}
          sx={(theme) => ({
            borderRadius: 1,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
              borderRadius: 1
            }
          })}
        >
          <AccountCircle
            sx={{ fontSize: 40 }}
            aria-controls="simple-menu"
            aria-haspopup="true"
          />
          <Typography
            sx={(theme) => ({
              paddingLeft: theme.spacing(1)
            })}
          >
            {userDisplayName}
          </Typography>
        </IconButton>
        <Menu
          sx={{ mt: "45px" }}
          data-testid={"supportAppBar-user-menu"}
          MenuListProps={{
            "data-testid": "supportAppBar-user-menu-list"
          } as Partial<MenuListProps>}
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem data-testid={"supportAppBar-user-menu-menuItem-logout-button"} onClick={handleLogout}>
            <Typography textAlign="center">{"Logout"}</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </Toolbar>
  </AppBar>;
}