import { Box } from "@mui/material";
import React from "react";
import SupportAppBar from "./SupportAppBar";
import { Outlet } from 'react-router-dom'
import { LoadingScreen } from "./LoadingScreen";

export const RootLayout = ({ children }) => {
  return (
    <Box
      sx={ theme => ({
        width: '100%',
        textAlign: 'start',
        display: 'flex',
        flexFlow: 'column',
        height: '100vh',
      })}
    >
      {children}
    </Box>
  )
}

export const BasicLayout = () => {
  return (
    <RootLayout>
      <SupportAppBar />
      <Box
        component="main"
        sx={ theme => ({
          m: theme.spacing(2, 0, 0, 0),
          background: (theme) => theme.palette.greys.extraLight,
          flex: 1,
          overflow: 'auto',
        })}
      >
        <Outlet />
      </Box>
    </RootLayout>
  )
}

export const SuspenseLayout = () => {
  return (
    <RootLayout>
      <SupportAppBar />
      <Box
        component="main"
        sx={ theme => ({
          m: theme.spacing(2, 0, 0, 0),
          background: (theme) => theme.palette.greys.extraLight,
          flex: 1,
          overflow: 'auto',
        })}
      >
        <LoadingScreen message={'Loading'} />
      </Box>
    </RootLayout>
  )
}