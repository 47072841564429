import { useFormContext } from "saga-library/src/components/Form";

export const useSchemaRequired = (name?: string): boolean => {
  const formMethods = useFormContext();

  if (!name || !formMethods) return false

  const { control, formState: {defaultValues}, getValues } = formMethods

  if (!control?._options?.context?.requiredSchema) return false

  const describeValues = {value:  {...defaultValues, ...getValues()}, context: control?._options?.context}
  const describedSchema = control?._options?.context?.requiredSchema?.describe(describeValues)

  if (!describedSchema) return false

  // parse field from the described schema
  // fields that have names like "NAME1.#.NAME2" are found in the described schema like so:
  // obj.fields[NAME1].innerType.fields[NAME2]

  const schemaField = name.split('.').reduce(
    (fieldObject, splitName) => {
      if (isNaN(Number(splitName))) {
        return fieldObject?.fields[splitName]
      } else {
        return fieldObject?.innerType
      }
    },
    describedSchema
  )

  return schemaField?.optional === false
}