import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useEffect, useState } from "react";
import TableCell from '@mui/material/TableCell'
import MuiCheckbox from "@mui/material/Checkbox";
import { Collapse, SxProps, TableBody } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Spinner } from "../Spinner";
import { IconButton } from "../Button";
import { Theme } from "@mui/system";

interface RowProps {
  cells: CellProps[]
  onClick?: (() => void) | null
  showCheckbox?: boolean
  onCheckboxSelect?: (number) => void
  onExpansion?: () => void
  checked?: boolean
  hasExpandableData?: boolean
  getExpandedTable?: () => Promise<any>
  expandable?: boolean
  expandedTableName?: string
  forceClose?: boolean
  loadingExpansion?: boolean
  key: number | string
  dataTestId?: string
  isWidget?: boolean
  leftAlignColumns?: boolean
  isClicked?: boolean
}

export const TableListRow = ({
  cells,
  onClick,
  showCheckbox,
  onCheckboxSelect,
  onExpansion,
  getExpandedTable,
  checked = false,
  hasExpandableData = false,
  expandable = false,
  forceClose = false,
  loadingExpansion = false,
  expandedTableName = '',
  dataTestId,
  isWidget = false,
  leftAlignColumns = false,
  isClicked = false,
}: RowProps) => {
  const [isExpanded, setExpanded] = useState<boolean>(false)
  const [formattedRows, setFormattedRows] = useState<any>([])
  const columnLength = { header: cells.length - 1, body: cells.length + 1 }
  const expandIconWidth = '24px'

  useEffect(() => {
    if (forceClose) {
      setExpanded(false)
    }
  }, [forceClose])

  const getBorderStyle = () => {
    if (isExpanded) {
      return "none"
    }
    return "default"
  }

  const showMoreRows = async (showMore) => {
    setExpanded(showMore)
    if (showMore && getExpandedTable) {
      const result = await getExpandedTable();
      if (result.length > 0) {
        setFormattedRows(
          result.map((row, i) => (
            <TableRow key={'row-hidden-' + i}>
              {row.map((cellProps, j) => (
                <TableListCell {...cellProps} key={'cell-hidden-' + j} onClick={() => onClick?.()} styles={{ color: (theme) => theme.palette.greys.light, border: "none"}} />
              ))}
              <TableListCell key={'cell-hidden-arrow'} sx={{ border: "none", width: expandIconWidth }}> <></> </TableListCell>
            </TableRow>
          ))
        )
        if (onExpansion) {
          onExpansion()
        }
      } else {
        setExpanded(false)
      }
    }
  }

  const getBackgroundColor = () => {
    if (isClicked) {
      return 'backgrounds.selectedHover'
    }
    if (onClick) {
      return 'backgrounds.hover'
    }
    return 'backgrounds.none'
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{
          backgroundColor: isClicked ? 'backgrounds.selected' : 'backgrounds.none',
          cursor: onClick ? 'pointer' : 'default',
          height: isWidget ? "auto" : "40px",
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: getBackgroundColor,
          }
        }}
        data-testid={`tableRow-${dataTestId}`}
      >
        { showCheckbox &&
          <TableListCell
            sx={{padding: '0 !important'}}
            key={'cell-checkbox'}
            children={
              <MuiCheckbox
                data-testid={`${dataTestId}-checkbox`}
                sx={{ color: 'primary' }}
                onChange={onCheckboxSelect}
                checked={checked}
              />
            }
          />
        }
        { cells.map(({sx, ...rest}, i) => (
            <TableListCell
              {...rest}
              key={'cell-' + i}
              onClick={() => onClick?.()}
              sx={{
                paddingRight: leftAlignColumns ? '24px' : '8px',
                borderBottom: getBorderStyle(),
                ...sx
              }}
            />
          ))
        }
        { leftAlignColumns &&
          <TableListCell key={'cell-blank'} sx={{ width: "100%", borderBottom: getBorderStyle() }} onClick={() => onClick?.()} />
        }
        { expandable &&
          <TableListCell key={'cell-history-expand'} sx={{ width: expandIconWidth, borderBottom: getBorderStyle() }}>
            { hasExpandableData ?
              <IconButton
                aria-label="expand-row"
                size="small"
                onClick={() => showMoreRows(!isExpanded)}
                icon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                dataTestId={`${dataTestId}-expandRow-button`}
              />
              : <></>
            }
          </TableListCell>
        }
      </TableRow>
      { hasExpandableData &&
        <TableRow>
          <TableCell style={{ padding: 0, border: "none" }} colSpan={columnLength.body}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableListCell sx={{ lineHeight: '25px', border: "none" }} />
                    <TableListCell sx={{ color:(theme) => theme.palette.greys.medium, backgroundColor:(theme) => theme.palette.tertiary.main, padding: '0px', lineHeight: '25px', border: "none", fontSize: '12px'}} colSpan={ columnLength.header } data={ expandedTableName } />
                  </TableRow>
                </TableHead>
                <TableBody sx={{ borderBottom: '1px solid #e0e0e0' }}>
                  { loadingExpansion ?
                    <TableRow>
                      <TableListCell colSpan={columnLength.body}>
                        <Spinner size={'md'} label={"Loading"} thickness={4} />
                      </TableListCell>
                    </TableRow>
                    : formattedRows }
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      }
    </React.Fragment>
  )
}

export interface CellProps {
  data?: string
  sx?: SxProps<Theme>
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit'
  children?: React.ReactNode
  onClick?: () => void
  colSpan?: number
}
export const TableListCell = ({ data, sx, align, children, onClick, colSpan }: CellProps) => {
  const dataStyles = data && {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '42px',
  }
  return (
    <TableCell
      sx={{
        padding: '4px',
        whiteSpace: 'nowrap',
        paddingLeft: '8px',
        paddingRight: '8px',
        ...dataStyles,
        ...sx,
      }}
      align={align}
      onClick={onClick}
      colSpan={colSpan}
    >
      {children ? children : data}
    </TableCell>
  )
}
