import React from "react";
import InputLabel from "@mui/material/InputLabel";
import { useTheme } from "@mui/material";
import { FieldError } from "../Form";

export interface InputLabelWithWarningProps {
  dataTestId?: string
  error?: FieldError
  label: string
  inputLabelStyle?: object
  variant?: 'outlined' | 'filled' | 'standard'
}

const InputLabelWithWarning = ({
  dataTestId,
  error,
  label,
  inputLabelStyle,
  variant='outlined'
} : InputLabelWithWarningProps) => {
  const theme = useTheme()
  return (
    <InputLabel
      variant={variant}
      data-testid={dataTestId}
      error={!!error}
      size={'small'}
      sx={{
        color: error?.type === "warning" ? theme.palette.warning.main : '',
        ...inputLabelStyle
      }}
    >
      {label}
    </InputLabel>
  )
}

export default InputLabelWithWarning