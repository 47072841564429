import React from 'react'
import TableContainer from "@mui/material/TableContainer";

interface StyledTableContainerInterface {
  children: React.ReactNode,
  sx?: any,
  tableContainerRef?: React.RefObject<HTMLDivElement>
}

export const StyledTableContainer = ({
  children,
  sx,
  tableContainerRef
}: StyledTableContainerInterface) => {
  return (
    <TableContainer
      ref={tableContainerRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        overflowY: 'auto',
        alignItems: 'flex-start',
        ...sx,
      }}
    >
      {children}
    </TableContainer>
  )
}