import Box from "@mui/material/Box";
import * as React from "react";
import { MouseEventHandler } from "react";


export default function Logo() {
  return <Box
    component="img"
    sx={{
      height: "4em",
    }}
    alt="Saga Support"
    src={'/saga-support-logo.png'}
  />
}

export function LogoBlackOnWhite() {
  return <Box
    component="img"
    sx={{
      height: "4em",
    }}
    alt="Saga Support"
    src={'/saga-support-logo-black.png'}
  />
}

type LogoProps = {
  onClick?: MouseEventHandler
}

export function LogoWhiteOnGreen({ onClick } : LogoProps) {
  return <Box
    component="img"
    data-testid={"supportLogo"}
    sx={{
      height: "4em",
    }}
    alt="Saga Support"
    src={'/saga-support-logo-white-on-green.png'}
    onClick={onClick}
  />
}