export enum PhoneEnumType {
  HOME = 'HOME',
  MOBILE = 'MOBILE',
  BUSINESS = 'BUSINESS',
  OTHER = 'OTHER',
  NEW = '',
}

export const PHONE_TYPES: { [key in PhoneEnumType]: string } = {
  [PhoneEnumType.HOME]: "Home",
  [PhoneEnumType.MOBILE]: "Mobile",
  [PhoneEnumType.BUSINESS]: "Business",
  [PhoneEnumType.OTHER]: "Other",
  [PhoneEnumType.NEW]: ""
}

export type PhoneType = {
  id?: string | null
  type: PhoneEnumType
  number: string
  notes?: string
  extension: string
  isPrimary: boolean
}

export type Phones = {
  phones: PhoneType[]
}

