import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from "saga-library/src/components/Form";
import {
  DataGrid as MuiDataGrid,
  GridColDef,
  GridToolbarContainer,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridSlots,
  GridRowId,
  GridRowModel,
  GridRenderEditCellParams,
  GridPreProcessEditCellProps,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCellParams,
  useGridApiRef
} from '@mui/x-data-grid'
import { AddButton } from '../AddButton'
import { RemoveButton } from '../RemoveButton'
import { Box } from '@mui/material'
import { GridDatePicker } from '../DatePicker'
import moment from 'moment-timezone'
import dayjs from 'dayjs'

export const borderlessFieldsSx = {
  width: '100%',
  mt: '-8px',
  '& .MuiInputBase-input': {
    fontSize: '12px'
  },
  '& .MuiOutlinedInput-root': {
    border: 'none',
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    }
  },
}

export type ExtendedGridColDef = GridColDef & {
  required?: boolean
};

export interface DataGridProps extends Omit<SimpleDataGridProps,  'initialRows' | 'onRowsChange'> {
  name: string
}

export const DataGrid = ({
  name,
  ...props
}: DataGridProps) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }}) => (
        <SimpleDataGrid
          {...props}
          initialRows={value || []}
          onRowsChange={(newRows) => onChange(newRows)}
          setValue={setValue}
        />
      )}
    />
  )
}


export const blurDataGrid = async (dataGridRef: React.MutableRefObject<any>) => {
  // Remove focus from DataGrid to process row update
  if (dataGridRef.current) {
    dataGridRef.current.blur()
  }
  // Small delay required for the row update to complete
  await new Promise(resolve => setTimeout(resolve, 500))
}

function convertToMoment(value: string | dayjs.Dayjs): moment.Moment {
  if (typeof value === 'string') {
    return moment(value)
  } else {
    return moment(value.toISOString()).utc()
  }
}

const validateDateInput = (params: GridPreProcessEditCellProps) => {
  const testDate = params.props.value
  if (testDate) {
    try {
      const newValue = convertToMoment(testDate)
      return { ...params.props, error: !newValue.isValid() };
    } catch (e) {
      return { ...params.props, error: true };
    }
  }
  return { ...params.props, error: false };
}

function wrapCellInError(node, error) {
  return (
    <Box
      sx={{
        width:'100%',
        border: (theme) => error ? `1px solid ${theme.palette.error.main}` : 'none',
      }}
    >
      {node}
    </Box>
  )
}



interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

export interface SimpleDataGridProps {
  initialColumns: ExtendedGridColDef[]
  initialRows: any[]
  dataTestId: string
  addLabel?: string
  checkboxSelection?: boolean
  disabled?: boolean
  format?: string
  height?: string
  onRowsChange?: (newRows: any[]) => void
  emptyListMessage?: string
  dataGridRef?: React.MutableRefObject<any>
  setValue?: (name: string, value: any, options?: any) => void
  autoFocus?: boolean
}

export const SimpleDataGrid = ({
  initialColumns,
  initialRows,
  addLabel,
  dataTestId,
  checkboxSelection = false,
  disabled,
  onRowsChange,
  format = 'YYYY/MM/DD',
  emptyListMessage = 'There are no items in this list',
  height,
  dataGridRef,
  setValue,
  autoFocus = false
}: SimpleDataGridProps) => {
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [hasFocus, setHasFocus] = useState(false)
  const apiRef = useGridApiRef()

  useEffect(() => {
    setRows(initialRows)
  }, [initialRows])

  useEffect(() => {
    if (setValue) {
      const isEditing = Object.values(rowModesModel).some((rowMode) => rowMode.mode === GridRowModes.Edit)
      setValue('isEditing', isEditing, { shouldDirty: false })
    }
  }, [rowModesModel, setValue])

  const getTargetCell = useCallback((id, field) => {
    if (dataGridRef?.current) {
      return dataGridRef.current.querySelector(`[data-id="${id}"] [data-field="${field}"]`) as HTMLElement
    }
    return null
  }, [dataGridRef])

  const setFocusAndCursor = useCallback((id, field) => {
    setTimeout(() => {
      const editableCell = getTargetCell(id, field)
      if (editableCell) {
        editableCell.focus()
        setTimeout(() => {
          const inputElement = editableCell.querySelector(field === 'Actions' ? 'button' : 'input')
          if (inputElement) {
            inputElement.focus()
          }
        }, 100)
      }
    }, 100)
  }, [getTargetCell])

  useEffect(() => {
    // adjust empty first row to have focus
    if (addLabel && rows.length !== Object.keys(rowModesModel).length && rows.length === 1) {
      const { id, ...rest } = rows[0]
      if (Object.values(rest).every((value) => !value)) {
        setRows([{ ...rows[0], isNew: true }])
        setRowModesModel((oldModel) => ({
          [id]: { mode: GridRowModes.Edit, fieldToFocus: columns[0].field },
          ...oldModel,
        }))
        setFocusAndCursor(id, columns[0].field)
      }
    }
  }, [rowModesModel, rows, setFocusAndCursor])

  const handleDeleteClick = useCallback((id: GridRowId) => () => {
    const updatedRows = rows.filter((row) => row.id !== id)
    setRows(updatedRows)
    if (onRowsChange) {
      onRowsChange(updatedRows)
    }
    if (setValue) {
      const testRowModesModel = { ...rowModesModel }
      delete testRowModesModel[id]
      const isEditing = Object.values(testRowModesModel).some((rowMode) => rowMode.mode === GridRowModes.Edit)
      setValue('isEditing', isEditing, { shouldDirty: false })
    }
  }, [rows, onRowsChange, setRows, setValue, rowModesModel])

  const columns: GridColDef[] = useMemo( () => {
    const tempColumns = initialColumns.map((column) => {
      const { required, ...rest } = column;
      let tempColumn: GridColDef = {
        ...rest,
        flex: 1,
        editable: disabled ? !disabled : rest.editable,
        preProcessEditCellProps: (params) => {
          if (required) {
            return { ...params.props, error: !params.props.value }
          }
          return params.props
        },
      }

      const renderEditCell = tempColumn.renderEditCell
      if (required && renderEditCell) {
        tempColumn = {
          ...tempColumn,
          renderEditCell: (params: GridRenderEditCellParams) => {
            const { error } = params;
            return wrapCellInError(renderEditCell(params), error)
          }
        }
      }

      if (rest.field === 'date') {
        const datePicker = (params) => (
          <GridDatePicker
            dataTestId={dataTestId}
            format={format}
            {...params}
          />
        )
        tempColumn = {
          ...tempColumn,
          preProcessEditCellProps: validateDateInput,
          renderCell: params => params?.value && convertToMoment(params?.value).isValid() ?
            convertToMoment(params?.value).format(format) : params?.value,
          renderEditCell: (props: GridRenderEditCellParams) => {
            const { error } = props
            if (error && setValue) {
              setValue('isEditing', true, { shouldDirty: false })
            }
            return wrapCellInError(datePicker(props), error)
          },
          flex: undefined,
          width: 220
        };
      }

      return tempColumn
    })

    const actions: GridColDef = {
      field: 'Actions',
      type: 'actions',
      headerName: 'Actions',
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <RemoveButton
            onClick={handleDeleteClick(id)}
            dataTestId={dataTestId}
            disabled={disabled}
          />
        ];
      }
    }

    if (addLabel) {
      return [...tempColumns, actions]
    }
    return tempColumns
  }, [initialColumns, addLabel])

  const changeRows = useCallback((currentRows, currentColumns, id, offset, nextColumnIndex, event) => {
    const currentRowIndex = currentRows.findIndex(row => row.id === id)
    if (currentRowIndex !== -1) {
      event.preventDefault()
      const nextRow = currentRows[currentRowIndex + offset]
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.View },
        [nextRow.id]: { mode: GridRowModes.Edit },
      }))
      setFocusAndCursor(nextRow.id, currentColumns[nextColumnIndex].field)
    }
  }, [setRowModesModel, setFocusAndCursor])

  function CustomToolbar(props: EditToolbarProps) {
    if (addLabel) {
      const { setRows, setRowModesModel } = props;

      const handleAddClick = () => {
        const id = crypto.randomUUID()
        setRows((oldRows) => [{ id, isNew: true }, ...oldRows]);
        setRowModesModel((oldModel) => ({
          [id]: { mode: GridRowModes.Edit, fieldToFocus: columns[0].field },
          ...oldModel,
        }))

        // Set the text cursor within the first editable cell
        setFocusAndCursor(id, columns[0].field)
      }

      return (
        <GridToolbarContainer>
          <AddButton
            label={`Add ${addLabel.toLowerCase()}`}
            dataTestId={dataTestId}
            onClick={handleAddClick}
            disabled={disabled}
          />
        </GridToolbarContainer>
      )
    }

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow }
    const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    setRows(updatedRows)
    if (onRowsChange) {
      onRowsChange(updatedRows)
    }
    return updatedRow
  }

  const handleCellClick = useCallback((params: GridCellParams, event: React.MouseEvent) => {
    // Check if the delete button was clicked
    if ((event.target as HTMLElement).closest('.MuiDataGrid-actionsCell')) {
      return
    }

    // Check if the cell is already in edit mode
    if (params.isEditable && params.cellMode === 'edit') {
      return
    }

    setRowModesModel((oldModel) => {
      const newModel = { ...oldModel }
      Object.keys(newModel).forEach((id) => {
        newModel[id] = { mode: GridRowModes.View }
      })
      newModel[params.id] = { mode: GridRowModes.Edit }
      return newModel
    })

    setFocusAndCursor(params.id, params.field)
  }, [])

  const handleCellKeyDown = (params: GridCellParams, event: React.KeyboardEvent) => {
    const visibleColumns = apiRef.current.getVisibleColumns()
    const orderedRows = apiRef.current.getSortedRows()

    if (dataGridRef?.current && event.key === 'Tab') {
      let nextColumnIndex = apiRef.current.getColumnIndex(params.field)

      if (event.shiftKey) {
        nextColumnIndex -= 1
        if (nextColumnIndex < 0) {
          nextColumnIndex = visibleColumns.length - 1
        }
        if (params.field === visibleColumns[0].field) {
          if (params.id === rows[0].id) {
            // at the top and should return to regular functionality
            setRowModesModel((oldModel) => ({
              ...oldModel,
              [params.id]: { mode: GridRowModes.View },
            }))
            return
          } else {
            // make the row above edit mode and the current row view mode
            changeRows(orderedRows, visibleColumns, params.id, -1, nextColumnIndex, event)
            return
          }
        }
      } else {
        nextColumnIndex += 1
        if (nextColumnIndex >= visibleColumns.length) {
          nextColumnIndex = 0
        }
        if (params.field === visibleColumns[visibleColumns.length - 1].field) {
          if (params.id === orderedRows[orderedRows.length - 1].id) {
            // at the bottom and should return to regular functionality
            setRowModesModel((oldModel) => ({
              ...oldModel,
              [params.id]: { mode: GridRowModes.View },
            }))
            return
          } else {
            // make the row below edit mode and the current row view mode
            changeRows(orderedRows, visibleColumns, params.id, 1, nextColumnIndex, event)
            return
          }
        }
      }

      event.preventDefault()
      if (params.cellMode !== GridRowModes.Edit) {
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [params.id]: { mode: GridRowModes.Edit },
        }))
      }
      setFocusAndCursor(params.id, visibleColumns[nextColumnIndex].field)
    }
  }

  const handleFirstKeyDown = useCallback((event: KeyboardEvent) => {
    const sortedRows = apiRef.current.getSortedRows()
    const visibleColumns = apiRef.current.getVisibleColumns()
    if (!hasFocus && event.key === 'Tab' && sortedRows.length > 0) {
      event.preventDefault()
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [sortedRows[0].id]: { mode: GridRowModes.Edit },
      }))
      setFocusAndCursor(sortedRows[0].id, visibleColumns[0].field)
      setHasFocus(true)
    }
  }, [hasFocus, setFocusAndCursor, setRowModesModel, setHasFocus])

  useEffect(() => {
    const sortedRows = apiRef.current.getSortedRows()
    if (sortedRows.length > 0 && !hasFocus && autoFocus) {
      const { id, ...rest } = sortedRows[0]
      if (!Object.values(rest).every((value) => !value)) {
        window.addEventListener('keydown', handleFirstKeyDown)
      }
    } else {
      window.removeEventListener('keydown', handleFirstKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleFirstKeyDown)
    }
  }, [hasFocus, handleFirstKeyDown])

  return (
    <Box sx={{ height: height || '100%' }} >
      <MuiDataGrid
        apiRef={apiRef}
        ref={dataGridRef}
        data-testid={`${dataTestId}-data-grid`}
        rows={rows}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        onCellClick={handleCellClick}
        onCellKeyDown={handleCellKeyDown}
        editMode={'row'}
        columns={columns}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={true}
        localeText={{ noRowsLabel: emptyListMessage }}
        getRowHeight={() => 40}
        disableColumnResize={!!addLabel}
        slots={{
          toolbar: CustomToolbar as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: addLabel ? 10 : 18,
            },
          },
        }}
        pageSizeOptions={addLabel ? [10] : [18]}
        sx={{
          border: 'none !important',
          '& .MuiDataGrid-root .MuiDataGrid-main': {
            border: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'backgrounds.hover',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
            color: 'greys.medium',
          },
          '& .MuiDataGrid-cell--editable:hover': {
            cursor: 'pointer',
          },
        }}
      />
    </Box>
  )
}

export default DataGrid