import { IconButton } from "../Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";

export const BackButton = ({ onClick, sx={}, dataTestId='' }) => {
  return <IconButton
    dataTestId={dataTestId}
    onClick={onClick}
    aria-label="back-icon"
    sx={{...sx}}
    icon={<ArrowBackIcon />}
    />
}

export default BackButton