import * as React from 'react'
import MuiRadioGroup from '@mui/material/RadioGroup'
import { Controller, useFormContext } from "saga-library/src/components/Form";
import Box from '@mui/material/Box'
import { FormControl, FormLabel, SxProps, Theme, useTheme } from "@mui/material";
import { Radio } from "./index";

interface RadioGroupProps {
  name: string
  label?: string
  value?: string
  onChange?: (e) => void
  row?: boolean
  disabled?: boolean
  sx?: SxProps<Theme>
  options: {label: string, value: any}[]
  dataTestId?: string
}

export const RadioGroup = ({
  label,
  name,
  row = false,
  sx,
  options,
  onChange,
  disabled,
  dataTestId
}: RadioGroupProps) => {
  const { control } = useFormContext()
  return (
    <Box>
      {label && <FormControl data-testid={dataTestId} disabled={disabled}> {label} </FormControl>}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange:controlledOnChange, value: fieldValue = '-1' } }) => {
          return (
            <MuiRadioGroup
              onChange={(e) => {
                controlledOnChange(e)
                if (onChange) {
                  onChange(e)
                }
              }}
              value={fieldValue}
              row={row}
              name={name}
              data-testid={`${dataTestId}-radiogroup`}
              sx={{
                display: 'flex',
                flexDirection: row ? 'row' : 'column',
                justifyContent: 'space-between',
                ...sx
              }}
            >
              {options.map((option, index) => <Radio dataTestId={`${dataTestId}-radiobutton-${index}`} value={option.value} label={option.label} key={option.value}/>)}
            </MuiRadioGroup>
          )
        }}
      />
    </Box>
  )
}

export function SimpleRadioGroup({
  label,
  name,
  value,
  onChange,
  children,
  row,
  disabled = false,
  dataTestId = ''
}) {
  const theme = useTheme()
  return (
    <FormControl
      data-testid={dataTestId}
      disabled={disabled}
    >
      <FormLabel> { label } </FormLabel>
      <MuiRadioGroup
          aria-label={label}
          name={name}
          data-testid={`${dataTestId}-radiogroup`}
          value={value}
          onChange={onChange}
          row={row}
          style={{
            marginLeft:row? 0 : theme.spacing(2),
          }}
        >
        {children}
      </MuiRadioGroup>
    </FormControl>)
}

export default RadioGroup
