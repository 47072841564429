import React from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Button } from "../Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";

interface AddButtonProps extends MuiButtonProps {
  label: string
  dataTestId: string
  iconVariant?: 'outlined' | 'contained'
  size?: 'small'
}
const AddButton = ({ label, dataTestId, size = 'small', iconVariant = 'contained', ...props }: AddButtonProps) => {
  const circleIcon = iconVariant === 'outlined' ? <AddCircleOutlineIcon /> :  <AddCircleIcon />
  return (
    <Button
      name={'add-button'}
      dataTestId={`${dataTestId}-add-button`}
      startIcon={circleIcon}
      variant={'text'}
      size={size}
      sx={{ alignSelf: 'flex-start' }}
      {...props}
    >
      {label}
    </Button>
  )
}

export default AddButton
