import React from 'react'
import TableCell from '@mui/material/TableCell'
import { SxProps, TableSortLabel } from "@mui/material";
import Box from '@mui/material/Box'
import { visuallyHidden } from '@mui/utils'
import type { Order } from './TableSortUtils'
import { TableHeaderTypography } from "../Typography/Typography";
import { Theme } from "@mui/system";

export interface HeaderCellProps {
  name: string
  sx?: SxProps<Theme>
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit'
  children?: React.ReactNode
  sortable?: boolean
  order: Order
  orderBy: string
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  dataTestId?: string
  lastCol?: boolean
}

export const TableListHeaderCell = (props: HeaderCellProps) => {
  const {
    name,
    sx,
    align,
    children,
    sortable = false,
    order,
    orderBy,
    dataTestId,
    lastCol= false,
  } = props
  return (
    <TableCell
      align={align}
      sx={{
        color: 'greys.medium',
        fontSize: '12px',
        width: lastCol ? '100%' : 'auto',
        whiteSpace: 'nowrap',
        ...sx,
      }}
      sortDirection={orderBy === name ? order : false}
      data-testid={`${dataTestId}-tableListHeaderCell-tableCell`}
    >
      {sortable ?
        <SortableHeader
          {...props}
          dataTestId={`${dataTestId}-tableListHeaderCell-sortableHeader`}
        /> :
        children ? children :
          <TableHeaderTypography
            dataTestId={`${dataTestId}-tableListHeaderCell-tableHeaderTypography`}
          >
            {name}
          </TableHeaderTypography>}
    </TableCell>
  )
}

export const SortableHeader = (props: HeaderCellProps) => {
  const { order, orderBy, onRequestSort, name, children, dataTestId } = props

  const createSortHandler =
    (key: string) => (event: React.MouseEvent<unknown>) => {
      if (onRequestSort) onRequestSort(event, key)
    }

  return (
    <TableSortLabel
      active={orderBy === name}
      direction={orderBy === name ? order : 'asc'}
      onClick={createSortHandler(name)}
      data-testid={`${dataTestId}-tableSortLabel`}
    >
      {children ? children :
        <TableHeaderTypography
          dataTestId={`${dataTestId}-tableSortLabel-tableHeaderTypography`}
        >
          {name}
        </TableHeaderTypography>}
      {orderBy === name &&
        <Box component="span" sx={visuallyHidden}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      }
    </TableSortLabel>
  )
}

