async function load() {
  if (process.env.NODE_ENV === 'test') {
    return require('./config.test.json')
  }
  if (process.env.NODE_ENV === 'development') {
    return require('./config.json')
  }

  return await fetch(
    '/config.json',
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then((r) => r.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error(error)
    })
}
export { load }
