import React from 'react'
import { Box } from "@mui/material";

interface LinkButtonProps {
  text: string
  onClick: () => void
  dataTestId?: string
}

export const LinkButtonHref = ({ text, onClick, dataTestId }: LinkButtonProps) => {
  return (
    <Box
      sx={{
        display: 'inline',
        alignItems: 'center',
        color: 'primary.main',
        cursor: 'pointer',
        '&:hover': {
          color: 'primary.dark',
        },
        textDecoration: 'underline'
      }}
      data-testid={dataTestId}
      onClick={onClick}
      role={'linkButton'}
    >
      <Box display={"inline"}> {text} </Box>
    </Box>
  )
}

export default LinkButtonHref