import React, { useEffect, useRef, useState } from 'react'
import Table from '@mui/material/Table'
import Checkbox from '@mui/material/Checkbox'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import { SxProps, TableBody, TableFooter } from '@mui/material'
import { defaultComparator } from './TableSortUtils'
import { TableListRow } from './TableListRow'
import { TableListHeaderCell } from './TableListHeaderCell'
import type { Order } from './TableSortUtils'
import { Spinner } from "../Spinner";
import { StyledTableContainer } from "./TableContainer"
import { Theme } from "@mui/system";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { IconButton } from "../Button";
import { EmptyList } from "../EmptyList";
import { EmptyListConfig } from "../EmptyList/EmptyList";
import { useUserInteraction } from 'saga-client/src/providers/UserInteractionContext'

export interface TableListHeaderConfig {
  name: string
  sx?: SxProps<Theme>
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit'
  children?: React.ReactNode
  sortable?: boolean
}

export interface TableListCellConfig {
  data?: string
  sx?: SxProps<Theme>
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit'
  children?: React.ReactNode
}

export interface TableListRowConfig {
  onClick?: (() => void) | null
  onRowSelectionChange?: (id?: number) => void
  isRowSelected?: boolean
  rowData: TableListCellConfig[]
  hasExpandableData?: boolean
  getExpandedTable?: () => Promise<any>
  loadingExpansion?: boolean
  key: number | string
  isRowClicked?: boolean
}

export interface TableListProps {
  columns: TableListHeaderConfig[]
  rows: TableListRowConfig[]
  emptyListComponent?: EmptyListConfig
  enableSorting?: boolean
  defaultSortOrder?: 'asc' | 'desc'
  defaultSortColumn?: number
  rowComparator?: (
    order: Order,
    orderBy: string,
    sortKeys: string[]
  ) => (a: TableListRowConfig, b: TableListRowConfig) => number
  size?: 'medium' | 'small'
  showHeader?: boolean
  sx?: SxProps<Theme>
  tableSx?: SxProps<Theme>
  showCheckbox?: boolean
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void
  loading?: boolean
  clearSelectedRows?: boolean
  setClearSelectedRows?: (value: boolean) => void
  expandable?: boolean
  expandedTableName?: string
  onCheckboxSelectionChanged?: (keys: any[]) => void,
  checkedRows?: string[]|null
  setCheckedRows?: ([]: any[]) => void | null,
  dataTestId?: string
  isWidget?: boolean
  leftAlignColumns?: boolean,
  scrollKey?: string,
  onRequestSort?: (key: string, order: string) => void,
  tableFooter?: React.ReactNode
}

export const TableList = ({
  columns,
  rows,
  emptyListComponent,
  rowComparator = defaultComparator,
  enableSorting = false,
  defaultSortOrder = 'asc',
  defaultSortColumn = 0,
  size = 'medium',
  showHeader = true,
  sx = {},
  tableSx = {},
  showCheckbox = false,
  onSelectAllClick,
  loading = false,
  clearSelectedRows = false,
  setClearSelectedRows,
  expandable = false,
  expandedTableName = '',
  onCheckboxSelectionChanged,
  checkedRows: externalCheckedRows,
  setCheckedRows: externalSetCheckedRows,
  dataTestId,
  isWidget = false,
  leftAlignColumns = false,
  scrollKey,
  onRequestSort,
  tableFooter
}: TableListProps) => {
  const [order, setOrder] = React.useState<Order>(defaultSortOrder)
  const headers: TableListHeaderConfig[] = leftAlignColumns ? [...columns, { name: '' }] : columns
  const [orderBy, setOrderBy] = React.useState<string>(defaultSortColumn >= 0 ? columns[defaultSortColumn].name : "")
  const sortKeys = columns.map((c) => c.name)
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false)
  const [expandedRows, setExpandedRows] = useState<(number|string)[]>([])
  const [closeAllOption, setCloseAllOption] = useState<boolean>(false)
  const [forceClose, setForceClose] = useState<boolean>(false)


  const [internalCheckedRows, internalSetCheckedRows] = useState<(number|string)[]>(externalCheckedRows || [])

  let checkedRows = internalCheckedRows
  let setCheckedRows = internalSetCheckedRows

  if (externalSetCheckedRows != null && externalCheckedRows != null) {
    checkedRows = externalCheckedRows
    setCheckedRows = externalSetCheckedRows as any
  }

  const listRef = useRef<HTMLDivElement>(null);
  const { saveScrollPosition, getScrollPosition } = useUserInteraction();

  const handleCloseAll = () => {
    if (closeAllOption) {
      setCloseAllOption(false)
      setExpandedRows([])
      setForceClose(true)
    }
  }

  const handleExpansionChange = (key: number | string) => {
    setForceClose(false)
    const currentExpandedRows: (number|string)[] = [...expandedRows]
    if (currentExpandedRows.includes(key)) {
      setExpandedRows([...currentExpandedRows.filter((k) => k !== key)])
      if (currentExpandedRows.length === 1) {
        setCloseAllOption(false)
      }
    } else {
      setExpandedRows([...currentExpandedRows, key])
      setCloseAllOption(true)
    }
  }

  const handleRowSelect = (key: number | string) => {
    const currentCheckedRows: (number|string)[] = [...checkedRows]
    if (currentCheckedRows.includes(key)) {
      setCheckedRows([...currentCheckedRows.filter((k) => k !== key)])
    } else {
      setCheckedRows([...currentCheckedRows, key])
    }
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, key: string) => {
    const isAsc = orderBy === key && order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'
    setOrder(newOrder)
    setOrderBy(key)
    if (onRequestSort) onRequestSort(key, newOrder)
  }

  const handleSelectAllClick = (e) => {
    if (checkedRows.length === rows.length) {
      setCheckedRows([])
      if (onSelectAllClick) onSelectAllClick(e)
    } else {
      setCheckedRows([...rows.map((row) => row.key)])
      if (onSelectAllClick) onSelectAllClick(e)
    }
  }

  useEffect(() => {
    if(externalCheckedRows != null && externalSetCheckedRows == null) {
      setCheckedRows(externalCheckedRows)
    }
  },[externalCheckedRows])

  useEffect(() => {
    if (clearSelectedRows) {
      setCheckedRows([])
      if (setClearSelectedRows) setClearSelectedRows(false)
    }
  }, [clearSelectedRows, setClearSelectedRows])

  useEffect(() => {
    if (checkedRows.length && checkedRows.length === rows.length) {
      setSelectAllChecked(true)
    } else {
      setSelectAllChecked(false)
    }
    if (onCheckboxSelectionChanged) onCheckboxSelectionChanged(checkedRows)
  }, [checkedRows, rows, onCheckboxSelectionChanged])

  useEffect(() => {
    requestAnimationFrame(() => {
      if (!!listRef.current && !!scrollKey && !loading) {
        listRef.current.scrollTop = getScrollPosition(scrollKey);
      }
    })
  }, [scrollKey, getScrollPosition, loading]);

  const onClick = (row: TableListRowConfig) => {
    if (listRef?.current && scrollKey) {
      saveScrollPosition(scrollKey, listRef.current.scrollTop);
    }

    row.onClick?.()
  }

  const showTableContent = (rows && rows.length > 0) && !loading
  const tableRows = showTableContent && enableSorting ? rows.slice().sort(rowComparator(order, orderBy, sortKeys)) : rows

  return (
      <StyledTableContainer sx={sx} tableContainerRef={listRef}>
        <Table
          size={size}
          sx={{flexShrink: 0, paddingRight: 1, ...tableSx}}
          stickyHeader
          data-testid={`${dataTestId}-tableList-table`}
        >
          {showHeader && (
            <TableHead key={'tableListHeader'} data-testid={`${dataTestId}-tableList-head`}>
              <TableRow data-testid={`${dataTestId}-tableList-tableRow`}>
                {showCheckbox &&
                  <TableListHeaderCell
                    key={'header-checkbox'}
                    name={'checkbox'}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    sx={{padding: '0px !important'}}
                    dataTestId={`${dataTestId}-tableList-tableListHeaderCell`}
                  >
                    <Checkbox
                      color={"primary"}
                      onChange={handleSelectAllClick}
                      checked={selectAllChecked}
                      data-testid={`${dataTestId}-tableList-tableListHeaderCell-checkbox`}
                    />
                  </TableListHeaderCell>
                }
                {headers.map((column, index) => {
                  const {sx: colSx, ...rest} = column
                  return <TableListHeaderCell
                    key={'header-' + index}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    sx={{ paddingLeft: '8px', paddingRight: '8px', ...colSx }}
                    dataTestId={`${dataTestId}-tableList-tableListHeaderCell`}
                    lastCol={ leftAlignColumns ? index === headers.length - 1 : false }
                    {...rest}
                  />
                })}
                {expandable &&
                  <TableListHeaderCell
                    key={'header-expand'}
                    name={'expand'}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    sx={{padding: '0px !important'}}
                    dataTestId={`${dataTestId}-tableList-tableListHeaderCell`}
                    align={'center'}
                  >
                    { closeAllOption ?
                      <IconButton
                        aria-label="header-expand"
                        size="small"
                        onClick={handleCloseAll}
                        icon={<KeyboardDoubleArrowUpIcon />}
                        dataTestId={`${dataTestId}-tableList-tableListHeaderCell-closeAll-button`}
                      />
                      : <></>
                    }
                  </TableListHeaderCell>
                }
              </TableRow>
            </TableHead>
          )}
          <TableBody key={'tableListBody'} data-testid={`${dataTestId}-tableList-body`}>
            {tableRows.map((row, index) => (
              <TableListRow
                cells={row.rowData}
                key={'row-' + row.key}
                isClicked={row.isRowClicked}
                showCheckbox={showCheckbox}
                onCheckboxSelect={() => {
                  handleRowSelect(row.key)
                  if (row.onRowSelectionChange) {
                    row.onRowSelectionChange()
                  }
                }}
                onClick={row.onClick ? () => onClick(row) : null}
                checked={!!checkedRows?.includes(row.key)}
                hasExpandableData={ row.hasExpandableData }
                getExpandedTable={ row.getExpandedTable }
                expandable={ expandable }
                expandedTableName = { expandedTableName }
                onExpansion={() => {
                  handleExpansionChange(row.key)
                }}
                forceClose={ forceClose }
                loadingExpansion={ row.loadingExpansion }
                isWidget={isWidget}
                leftAlignColumns={leftAlignColumns}
                dataTestId={`${dataTestId}-tableList-row-${index}`}
              />
            ))}
          </TableBody>
          {tableFooter &&
            <TableFooter key={'tableListFooter'} data-testid={`${dataTestId}-tableList-footer`}>
              {tableFooter}
            </TableFooter>
          }
        </Table>

        <EmptyOrLoading
          showTableContent={showTableContent}
          emptyListComponent={emptyListComponent}
          loading={loading}
          dataTestId={'tableList'}
        />
      </StyledTableContainer>
  )
}

export const EmptyOrLoading = ({showTableContent, emptyListComponent, loading, dataTestId}) => {
  if (loading) {
    return (
      <Box padding={8} textAlign={'center'}>
        <Spinner size={'xl'} label={"Loading"} thickness={4} dataTestId={`${dataTestId}-tableList-emptyOrLoading-spinner`}/>
      </Box>
    )
  }

  if (!showTableContent) {
    return <EmptyList
        message={emptyListComponent?.message || 'There are no items in this list'}
        size={emptyListComponent?.size}
        icon={emptyListComponent?.icon}
        sx={emptyListComponent?.sx}
        dataTestId={`${dataTestId}-tableList-emptyOrLoading-emptyList`}
      />
  }

  return null
}

export default TableList
