import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material";
import { FieldError } from "saga-library/src/components/Form";

interface FormHelperTextWithWarningProps {
  error?: FieldError
  helperText?: string
  dataTestId?: string
  variant?: 'outlined' | 'filled' | 'standard'
}

const FormHelperTextWithWarning = ({
  error,
  helperText,
  dataTestId,
  variant='outlined'
}: FormHelperTextWithWarningProps) => {
  const theme = useTheme()
  return (
    <FormHelperText
      variant={variant}
      data-testid={dataTestId}
      error={!!error}
      sx={{'&.MuiFormHelperText-root': { color: error?.type === "warning" ? theme.palette.warning.main : null }}}
    >
      {helperText}
    </FormHelperText>
  )
}

export default FormHelperTextWithWarning