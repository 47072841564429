import { styled } from "@mui/system";
import { Chip as MuiChip } from "@mui/material";

export const Chip = styled(MuiChip, { name: 'filled' })(({ theme, variant }) => ({
  backgroundColor: variant === 'filled' ? theme.palette.greys.dark : '',
  color: variant === 'filled' ? theme.palette.primary.contrastText : '',
  borderColor: variant === 'outlined' ? theme.palette.greys.dark : '',
  margin: 1,
  fontSize: '12px',
  fontWeight: '400',
  '&.MuiChip-sizeSmall': {height: '20px'}
})) as typeof MuiChip

export const PrimaryChip = styled(MuiChip, { name: 'filled' })(({ theme }) => ({
  backgroundColor: theme.palette.backgrounds.selected,
  color: theme.palette.primary.main,
  fontSize: '12px',
  fontWeight: '400',
  height: '20px',
  borderRadius: '16px'
})) as typeof MuiChip

export default Chip