


let idsGenerated = 0
let initSeconds = new Date().getTime()
export const disableAutocomplete = () => {
  idsGenerated += 1
  return `SAGA-INPUT-${initSeconds}-${idsGenerated}`
}



