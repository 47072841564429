import { BlobServiceClient, BlobUploadCommonResponse } from "@azure/storage-blob";

export type BaseFile = {
  id: string,
  fileUri: string,
  containerName: string,
  blobName: string,
  contentType: string,
}

export interface BaseFileWithProgress extends File {
  progress?: number
}

export async function uploadToAzure<
  TFileWithProgress extends BaseFileWithProgress,
  TFile extends BaseFile
> (
  endpoint: string,
  sasToken: string,
  file: TFileWithProgress,
  fileRecord: TFile
) : Promise<void> {
  if (!file || !fileRecord) throw Error("Error uploading file to Azure")

  const blobServiceClient = BlobServiceClient.fromConnectionString(`BlobEndpoint=${endpoint};SharedAccessSignature=${sasToken}`);
  const containerClient = blobServiceClient.getContainerClient(fileRecord.containerName);
  const blobClient = containerClient.getBlockBlobClient(fileRecord.blobName);

  const options = {
    blobHTTPHeaders: {
      blobContentType: fileRecord.contentType
    },
    blockSize: 4 * 1024 * 1024,
    concurrency: 4,
    onProgress: (ev) => {
      if (!ev.loadedBytes) return
      let progress = 0
      if (ev.loadedBytes > 0 && file.size > 0) {
        progress = (ev.loadedBytes / file.size) * 100
        progress = Number(progress.toFixed(2))
      }
      file.progress = progress
    },
    onerror: (ev) => {
      console.error('error uploading file to azure', ev)
    },
    onabort: (ev) => {
      console.error('abort uploading file to azure', ev)
    }
  }

  const response: BlobUploadCommonResponse = await blobClient.uploadData(file, options)

  if (response._response.status !== 201) {
    console.error('error uploading file to azure', response)
  }
}

export const formatFileSizeInMB = (size: number) => {
  return `${(size / 1000000).toFixed(2)} MB`
}