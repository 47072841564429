export const serviceCodeHospitalAdmission = ['03.03D', '03.03PD']

export const serviceCodeFeeModifierMaxSelection = 3

export const feeModUnitDescriptionList = [
  {
    feeMod: 'TM',
    unitDescription: `Number of 5 minute intervals`,
  },
  {
    feeMod: 'NBTR',
    unitDescription: `Number of trays (1-99)`,
  },
  {
    feeMod: 'CMGP',
    unitDescription: `Time spent with patient in minutes`,
  },
  {
    feeMod: 'NBPG',
    unitDescription: `Number of patients in group`,
  },
  {
    feeMod: 'TEV',
    unitDescription: `Number of 15 minute intervals`,
  },
  {
    feeMod: 'TNTA',
    unitDescription: `Number of 15 minute intervals`,
  },
  {
    feeMod: 'TNTP',
    unitDescription: `Number of 15 minute intervals`,
  },
  {
    feeMod: 'TST',
    unitDescription: `Number of 15 minute intervals`,
  },
  {
    feeMod: 'TWK',
    unitDescription: `Number of 15 minute intervals`,
  },
]

export enum NewbornCode {
  LIVEBIRTH = 'LIVE_BIRTH',
  ADOPTION = 'ADOPTION',
  STILLBORN = 'STILL_BORN',
  MULTIPLEBIRTH = 'MULTIPLE_BIRTH',
}

export enum LocationCode {
  HOME = 'HOME',
  SCHL = 'SCHOOL',
  OTHR = 'OTHER',
}

export enum PayToCode {
  BAPY = 'BUSINESS_ARRANGEMENT',
  CONT = 'CONTRACT_HOLDER',
  RECP = 'SERVICE_RECIPIENT',
  PRVD = 'SERVICE_PROVIDER',
  OTHR = 'OTHER',
}

export const PayToOptions = [
  { label: 'Business arrangement (BAPY)', value: PayToCode.BAPY },
  { label: 'Contract holder (CONT)', value: PayToCode.CONT },
  { label: 'Service recipient (RECP)', value: PayToCode.RECP },
  { label: 'Service provider (PRVD)', value: PayToCode.PRVD },
  { label: 'Other (OTHR)', value: PayToCode.OTHR },
]

export enum InterceptReason {
  HOLD = 'HOLD_FOR_PICKUP'
}

export enum AbClaimProblemSeverity {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum AbClaimStatus {
  DRAFT = 'DRAFT',
  HOLD = 'HOLD',
  READY = 'READY',
  QUEUED = 'QUEUED',
  SUBMITTED = 'SUBMITTED',
  ASSESSED = 'ASSESSED',
  HISTORICAL = 'HISTORICAL',
  RESUBMITTED = 'RESUBMITTED'
}

export const MultipleValuesBatchMessage = 'Multiple values entered'

export enum ABClaimAssessmentOutcome {
  REFUSED = 'REFUSED',
  HELD = 'HELD',
  APPLIED = "APPLIED",
}

export enum AssessedAbClaimSortColumn {
  CLAIM_IDENTIFIER = 'CLAIM_IDENTIFIER',
  SERVICE_DATE = 'SERVICE_DATE'
}

export enum ABClaimActionCode {
  ADD = 'ADD',
  CHANGE = 'CHANGE',
  DELETE = 'DELETE',
  REASSESS = 'REASSESS',
}

export enum AbClaimAcceptCode {
  ACCEPT = 'ACCEPT',
  WRONG_DOCTOR_OR_PATIENT = 'WRONG_DOCTOR_OR_PATIENT',
  PREVIOUSLY_PAID_OR_INCLUDED_IN_ANOTHER_SERVICE = 'PREVIOUSLY_PAID_OR_INCLUDED_IN_ANOTHER_SERVICE',
  BILLED_TO_PATIENT_OR_THIRD_PARTY = 'BILLED_TO_PATIENT_OR_THIRD_PARTY',
  BILLED_TO_ALTERNATE_BA_NUMBER = 'BILLED_TO_ALTERNATE_BA_NUMBER',
  WRITE_OFF_OR_UNCOLLECTABLE = 'WRITE_OFF_OR_UNCOLLECTABLE',
}
