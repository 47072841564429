import React, { createContext, useContext } from 'react';

export const ENCOUNTER_NOTES_SCROLL_KEY = "encounterNotes"
export const LOCATIONS_SCROLL_KEY = "locations"
export const FORMS_SCROLL_KEY = "forms"
export const LABS_AND_INVESTIGATIONS_SCROLL_KEY = "labsAndInvestigations"
export const OTHER_DOCUMENTS_SCROLL_KEY = "otherDocuments"
export const LETTERS_SCROLL_KEY = "letters"
export const PRESCRIPTIONS_SCROLL_KEY = "prescriptions"
export const ROLES_AND_PERMISSIONS_SCROLL_KEY = "rolesAndPermissions"
export const PHARMACIES_SCROLL_KEY = "pharmacies"
export const TIMELINE_SCROLL_KEY = "timeline"
export const TASK_TYPE_SCROLL_KEY = "taskTypes"
export const TASKS_SCROLL_KEY = "tasks"

const scrollKeys = [
  ENCOUNTER_NOTES_SCROLL_KEY,
  LOCATIONS_SCROLL_KEY,
  FORMS_SCROLL_KEY,
  LABS_AND_INVESTIGATIONS_SCROLL_KEY,
  OTHER_DOCUMENTS_SCROLL_KEY,
  LETTERS_SCROLL_KEY,
  PRESCRIPTIONS_SCROLL_KEY,
  ROLES_AND_PERMISSIONS_SCROLL_KEY,
  PHARMACIES_SCROLL_KEY,
  TIMELINE_SCROLL_KEY,
  TASK_TYPE_SCROLL_KEY,
  TASKS_SCROLL_KEY
]

export const LABS_AND_INVESTIGATIONS_FILTER_KEY = "labsAndInvestigationsFilter"
export const PRESCRIPTIONS_FILTER_KEY = "prescriptionsFilter"

const filterKeys = [
  LABS_AND_INVESTIGATIONS_FILTER_KEY,
  PRESCRIPTIONS_FILTER_KEY
]

interface UserInteractionContextInterface {
  saveScrollPosition: (pageKey: string, position: number) => void
  saveFilter: (filterKey: string, filter: string) => void
  getScrollPosition: (pageKey: string) => number
  getFilter: (filterKey: string) => string
  clearScrollPositions: () => void
}

const defaultUserInteractionContext: UserInteractionContextInterface = {
  saveScrollPosition: () => {},
  saveFilter: () => {},
  getScrollPosition: () => 0,
  getFilter: () => "",
  clearScrollPositions: () => {},
}

const UserInteractionContext = createContext(defaultUserInteractionContext)

export const UserInteractionProvider = ({ children }) => {
  const saveScrollPosition = (pageKey: string, position: number) => {
    sessionStorage.setItem(pageKey, position.toString())
  }

  const saveFilter = (filterKey: string, filter: string) => {
    sessionStorage.setItem(filterKey, filter)
  }

  const getScrollPosition = (pageKey: string) => {
    return parseInt(sessionStorage.getItem(pageKey) || "0") || 0
  }

  const getFilter = (filterKey: string) => {
    return sessionStorage.getItem(filterKey) || ""
  }

  const clearScrollPositions = () => {
    scrollKeys.map((key: string) => sessionStorage.removeItem(key))
    filterKeys.map((key: string) => sessionStorage.removeItem(key))
  }

  return (
    <UserInteractionContext.Provider value={{ saveScrollPosition, saveFilter, getScrollPosition, getFilter, clearScrollPositions }}>
      {children}
    </UserInteractionContext.Provider>
  );
};

export const useUserInteraction = () => useContext(UserInteractionContext);