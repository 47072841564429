import React, { useEffect } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useConfigurationContext } from './ConfigurationProvider'

/**
 * Apollo graphql client provider.
 * Handles APi authentication via msal + authorization headers.
 * @param children
 * @constructor
 */
export const Apollo = ({ children }) => {
  const { getConfigValue } = useConfigurationContext()
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const withToken = setContext(async (_, { headers }) => {
    const token = await RequestAccessToken()
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
      },
    }
  })

  const httpLink = createHttpLink({
    uri: getConfigValue('SUPPORT_GRAPHQL_ENDPOINT'),
  })

  const gql_client = React.useMemo(() => {
    return new ApolloClient({
      link: from([withToken, httpLink]),
      cache: new InMemoryCache({
        typePolicies: {
          Tenant: {
            merge: true,
          },
          Query: {
            fields: {
              tenant: {
                merge: true,
              },
            },
          },
        },
      }),
    })
  }, [httpLink, withToken])

  useEffect(() => {
    if (!isAuthenticated) {
      ;(async () => {
        await gql_client.resetStore() //clear Apollo cache when user logs off
      })()
    }
  }, [isAuthenticated, gql_client])

  const RequestAccessToken = async () => {
    if (!isAuthenticated) return null

    const request = {
      scopes: getConfigValue('SUPPORT_MSAL_LOGIN_SCOPES'),
      account: accounts[0],
    }

    try {
      const response = await instance.acquireTokenSilent(request)
      return response.accessToken
    } catch {
      const response = await instance.acquireTokenPopup(request)
      return response.accessToken
    }
  }

  return <ApolloProvider client={gql_client}>{children}</ApolloProvider>
}