import { TableListRowConfig } from './TableList'

export type Order = 'asc' | 'desc'

export const defaultComparator = (
  order: Order,
  orderBy: string,
  sortKeys: string[]
): ((a: TableListRowConfig, b: TableListRowConfig) => number) => {
  return order === 'desc'
    ? (a: TableListRowConfig, b: TableListRowConfig) =>
        defaultDescendingComparator(a, b, orderBy, sortKeys)
    : (a: TableListRowConfig, b: TableListRowConfig) =>
        -defaultDescendingComparator(a, b, orderBy, sortKeys)
}

const defaultDescendingComparator = (
  a: TableListRowConfig,
  b: TableListRowConfig,
  orderBy: string,
  sortKeys: string[]
) => {
  const sortKeyIndex = sortKeys.indexOf(orderBy)
  const aVal = a?.rowData[sortKeyIndex]?.data ?? ''
  const bVal = b?.rowData[sortKeyIndex]?.data ?? ''
  if (aVal < bVal) {
    return 1
  }
  if (aVal > bVal) {
    return -1
  }
  return 0
}

export const nullOrUndefinedComparison = (a:any,b:any): number | null => {
  const aIsNullOrUndefined = a === null || a === undefined
  const bIsNullOrUndefined = b === null || b === undefined

  if (aIsNullOrUndefined && bIsNullOrUndefined) {
    return 0
  } else if (aIsNullOrUndefined) {
    return 1
  } else if (bIsNullOrUndefined) {
    return -1
  }
  return null
}

export const nanComparison = (a:any,b:any): number | null => {
  const aIsNaN = isNaN(a)
  const bIsNaN = isNaN(b)

  if (aIsNaN && bIsNaN) {
    return 0
  } else if (aIsNaN) {
    return 1
  } else if (bIsNaN) {
    return -1
  }
  return null
}