import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, {AlertColor} from '@mui/material/Alert'
import ClearIcon from '@mui/icons-material/Clear'
import { IconButton } from '../Button'
import { IconButtonProps, SvgIconProps } from "@mui/material";

interface SimpleSnackbarType{
  open: boolean
  id: number
  message: string
  type?: AlertColor
  setSnackbar: (props) => void
  action?: React.ReactNode
  dataTestId?: string
}
export default function SimpleSnackbar({
  open,
  id,
  message,
  setSnackbar,
  action,
  type = "success",
  dataTestId
}: SimpleSnackbarType) {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbar({ open: false, snackbarMessage: {id: 0, message: '', type: "success"}})
  }

  let dismissAction = (
    <>
      {action && action}
      <IconButton
        data-testid={`${dataTestId}-dismiss`}
        onClick={handleClose}
        sx={{
          ml: action ? 3 : 0
        }}
        icon={<ClearIcon sx={{width:"20px", height:"20px", color: 'white'}}/>}
      />
    </>
  )

  let alertStyles = {}
  if(type === 'success'){
    alertStyles['backgroundColor'] = 'primary.main'
  }

  return (
    <Snackbar
      data-testid={dataTestId}
      key={id}
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}
      autoHideDuration={type !== "success" ? 6000 : 4000}
      role={'snackbar'}
    >
      <MuiAlert
        sx={{
          color: '#fff',
          ...alertStyles,
          alignItems:"center"
        }}
        variant={'filled'}
        onClose={handleClose}
        severity={type}
        action={type !== 'success' ? dismissAction : action}
        slotProps={{
          closeButton: {
            "data-testid": `${dataTestId}-closeButton`
          } as IconButtonProps,
          closeIcon: {
            "data-testid": `${dataTestId}-closeIcon`
          } as SvgIconProps
        }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  )
}
