import { LogLevel } from "@azure/msal-browser";
import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import { MsalProvider } from "@azure/msal-react";
import React, { useEffect } from "react";
import { useConfigurationContext } from "./ConfigurationProvider";

const msalLoggerCallback = (level, message, containsPii) => {
  if (containsPii) {
    return
  }
  switch (level) {
    case LogLevel.Error:
      console.error(message)
      return
    case LogLevel.Info:
      //console.info(message)
      return
    case LogLevel.Verbose:
      //console.debug(message)
      return
    case LogLevel.Warning:
      console.warn(message)
      return
  }
}

const defaultConfig : Configuration = {
  auth: {
    clientId: '',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: msalLoggerCallback,
    },
  },
}

type MQAuthProviderType = {
  instance: PublicClientApplication
}

const MQAuthContext = React.createContext<MQAuthProviderType>({
  instance: new PublicClientApplication(defaultConfig)
})

export const MQAuthProvider = ({children}) => {
  const { getConfigValue } = useConfigurationContext()
  const [msalInstance, setMsalInstance] = React.useState<PublicClientApplication>(new PublicClientApplication(defaultConfig))

  useEffect(() => {
    const config : Configuration = {
      ...defaultConfig,
      auth: {
        clientId: getConfigValue('SUPPORT_MSAL_CLIENT_ID')!,
        authority: getConfigValue('SUPPORT_MSAL_AUTHORITY'),
        redirectUri: getConfigValue('SUPPORT_MSAL_REDIRECT_URI'),
        postLogoutRedirectUri: getConfigValue('SUPPORT_MSAL_POST_LOGOUT_REDIRECT_URI'),
      },
    }
    setMsalInstance(new PublicClientApplication(config))
  }, [getConfigValue])

  const provider: MQAuthProviderType = {
    instance: msalInstance
  }

  return (
    <MQAuthContext.Provider value={provider}>
      {/* @ts-ignore */}
      <MsalProvider instance={msalInstance}>
        {children}
      </MsalProvider>
    </MQAuthContext.Provider>
  )
}

export const useMQAuthProvider = () => {
  return React.useContext(MQAuthContext)
}
