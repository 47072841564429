import React, { useState, useEffect, useCallback } from 'react'
import { load } from '../config/config'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { LoadingScreen } from '../components/LoadingScreen'
import { Configuration as ConfigurationType } from 'types/Configuration'

const react_env_prefix = 'REACT_APP_'

/**
 * Context definition for configuration exposure
 */
export const Configuration = React.createContext({
  getConfigValue: (key: string, optional: boolean = false): any => { return '' }
})

/**
 * Configuration provider.
 *
 * Loads data from a local config.json file for test and development environments.
 *
 * Loads data from the config.json file located at the root of the website (same location as index.html).
 * and provides a function for retrieving config values and a configLoaded
 * check value to prevent async load issues.
 *
 * @param children
 * @constructor
 */
export const ConfigurationProvider = ({ children }) => {
  const [configuration, setConfiguration] = useState<ConfigurationType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const getConfigValue = useCallback((key: string, optional: boolean = false) => {
    const env_key = react_env_prefix + key
    if (env_key in process.env) return process.env[env_key]

    const value = (configuration ?? {})[key]

    if (value == null && !optional) {
      console.error(`Configuration key ${key} does not exist.`)
      throw new Error(`Configuration key ${key} does not exist.`)
    }

    return value
  }, [configuration])

  useEffect(() => {
    if (!!configuration) return
    load()
      .then((config) => {
        setConfiguration(config)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [configuration])

  useEffect(() => {
    if (!configuration) return

    var ddToken = getConfigValue('DATADOG_CLIENT_TOKEN', true)
    var ddAppId = getConfigValue('DATADOG_APPLICATION_ID', true)
    var ddEnv = getConfigValue('DATADOG_ENV', true)

    if (ddToken && ddAppId && ddEnv) {
      datadogLogs.init({
        service: 'saga support client',
        clientToken: ddToken,
        forwardErrorsToLogs: process.env.NODE_ENV !== 'development',
        silentMultipleInit: true,
      })

      datadogRum.init({
        applicationId: ddAppId,
        clientToken: ddToken,
        site: 'datadoghq.com',
        service: 'saga-support-client',
        env: ddEnv,
        version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [getConfigValue('SAGA_API_URL', true)],
      });

      datadogRum.startSessionReplayRecording();
    }

  }, [configuration, getConfigValue])

  if (loading) {
    return <LoadingScreen message={'Loading'} />
  }

  return (
    <Configuration.Provider value={{ getConfigValue }}>
      {children}
    </Configuration.Provider>
  )
}

/**
 * Hook for accessing getSettings
 */
export const useConfigurationContext = () => {
  return React.useContext(Configuration)
}
