import * as React from 'react';
import './App.css';
import { lazy, Suspense } from "react";
import { BasicLayout, SuspenseLayout } from "./components/Layouts";
import { Apollo } from "./providers/ApolloProvider";
import { LoadingScreen } from "./components/LoadingScreen";
import { Routes, Route } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AlertsProvider } from "saga-library/src/providers/Alerts";

const SignIn = lazy(() => import("./pages/SignIn"));
const Home = lazy(() => import("./pages/Home"));
const Clinics = lazy(() => import("./pages/Clinics"));
const Forms = lazy(() => import("./pages/Forms"));
const NoMatch = lazy(() => import("./pages/NoMatch"));

function App() : JSX.Element {
  const isAuthenticated : boolean = useIsAuthenticated();
  const { inProgress } = useMsal();

  if (inProgress !== 'none') {
    if (inProgress === 'login' || inProgress === 'ssoSilent' || inProgress === 'handleRedirect') {
      return <LoadingScreen message={'Authenticating'} />
    }
    return <LoadingScreen message={''} />
  }

  if (!isAuthenticated) {
    return <SignIn />
  }

  return (
    <Apollo>
      <AlertsProvider>
        <Suspense fallback={<SuspenseLayout />}>
          <Routes>
            <Route element={<BasicLayout />} >
              <Route path="/" element={<Home />} />
              <Route path="/clinics" element={<Clinics />} />
              <Route path="/forms" element={<Forms />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </Suspense>
      </AlertsProvider>
    </Apollo>
  )
}

export default App;
