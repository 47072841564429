import React from 'react'
import { Spinner } from 'saga-library/src'
import Box from '@mui/material/Box'
import { Backdrop, Paper } from "@mui/material";

export const LoadingScreen = ({ message }) => {
  return (
    <Box width={'100%'} paddingTop={'25%'}>
      <Spinner label={message} />
    </Box>
  )
}

export const LoadingOverlay = ({ message, loading }) => {
  if(loading) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!loading}
      >
        <Paper sx={{ padding: 4 }}>
          <Spinner label={message} />
        </Paper>
      </Backdrop>
    )
  }

  return null
}

export const LoadingSpinner = ({overlay=false}) => {
  const style = {
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: (theme) => theme.zIndex.drawer + 1
  }

  const overlayStyle = {
    ...style,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }

  return (
    <Box sx={overlay? overlayStyle : style}>
      <Spinner label={"Loading"} size={'xl'} />
    </Box>
  )
}
