import { Box, SxProps } from "@mui/material";
import React from "react";
import { Typography } from "../Typography";
import { Theme } from "@mui/system";

interface StatusBadgeProps {
  text: string
  color: string
  sx?: SxProps<Theme>
}

const StatusBadge = ({text, color, sx={}}: StatusBadgeProps) => {
  return (
    <Box
      m={.75}
      p={.75}
      sx={{
        backgroundColor: addAlpha(color, .1),
        borderRadius: '8px',
        width: 'min-content',
        ...sx
      }}
    >
      <Typography
        style={{
          color: color,
          fontSize: '12px'
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

function addAlpha(color, opacity) {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export default StatusBadge