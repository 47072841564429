export const DATE_STRING = "Date";
export const DATE_OF_BIRTH_STRING = "Date of birth";
export const TODAYS_DATE_STRING = "Today's date";
export const SIGNATURE_STRING = 'Signature'

export const PRACTITIONER_TITLE_LAST_NAME = "Title. Last name";
export const PRACTITIONER_TITLE_FULL_NAME = "Title. First name Last name";

export const patientButtons = ['First name', 'Last name', DATE_OF_BIRTH_STRING, 'Full address', 'Age', 'Gender', 'Pronouns', 'Identifier', 'Primary phone number', 'Email address']
export const pronounButtons = ['He/She/They', 'he/she/they', 'Him/Her/Them', 'him/her/them', 'His/Her/Their', 'his/her/their']
export const patientHealthDataButtons = ['All allergies', 'Current condition and procedures', 'Current prescriptions', 'Length/Height (cm)', 'Length/Height (in)', 'Weight (kg)', 'Weight (lb)', 'BMI', 'Blood pressure', 'Heart rate', 'Head circumference (cm)', 'Head circumference (in)']
export const appointmentButtons = [DATE_STRING, 'Time', 'Type', 'Reason for visit', 'Practitioner', 'Location']
export const practitionerButtons = [PRACTITIONER_TITLE_LAST_NAME, PRACTITIONER_TITLE_FULL_NAME, 'Full address', 'Phone Number', 'Practitioner ID']
export const locationButtons = ['Name', 'Full address', 'Phone number', 'Fax number']
export const generalButtons = [TODAYS_DATE_STRING, "Text"]

export const dateFormats = ['YYYY/MM/DD', 'MM/DD/YYYY', 'DD/MM/YYYY', 'DD/MMM/YYYY', 'MMM D, YYYY', 'MMMM D, YYYY', 'dddd, MMMM D, YYYY']


export const AUTOFILL_MENU_PLUGIN = 'autofillMenuPlugin';
export const PATIENT_PROFILE_MENU_PLUGIN = 'patientProfileMenuPlugin';
export const PATIENT_HEALTH_DATA_MENU_PLUGIN = 'patientHealthDataMenuPlugin';
export const PRACTITIONER_MENU_PLUGIN = 'practitionerMenuPlugin';
export const APPOINTMENT_MENU_PLUGIN = 'appointmentMenuPlugin';
export const LOCATION_MENU_PLUGIN = 'locationMenuPlugin';
export const GENERAL_MENU_PLUGIN = 'generalMenuPlugin';

export const APPOINTMENT_LOCATION_MENU_PLUGIN = 'appointmentLocationMenuPlugin';
export const APPOINTMENT_PRACTITIONER_MENU_PLUGIN = 'appointmentPractitionerMenuPlugin';

export const DATE_MENU_PLUGIN = 'dateMenuPlugin';
export const PRONOUN_MENU_PLUGIN = 'pronounMenuPlugin';

export const LETTER_PRACTITIONER_MENU_PLUGIN = 'letterPractitionerMenuPlugin';
export const REFERRING_PRACTITIONER_MENU_PLUGIN = 'referringPractitionerMenuPlugin';
export const FAMILY_PRACTITIONER_MENU_PLUGIN = 'familyPractitionerMenuPlugin';

export const secondLayerMenus = [PATIENT_PROFILE_MENU_PLUGIN, PATIENT_HEALTH_DATA_MENU_PLUGIN, PRACTITIONER_MENU_PLUGIN,
  APPOINTMENT_MENU_PLUGIN, LOCATION_MENU_PLUGIN, GENERAL_MENU_PLUGIN]

export const thirdLayerMenus = [DATE_MENU_PLUGIN, PRONOUN_MENU_PLUGIN, APPOINTMENT_PRACTITIONER_MENU_PLUGIN, APPOINTMENT_LOCATION_MENU_PLUGIN]

export const autofillButtons = [
  { label: 'Patient profile', pluginName: PATIENT_PROFILE_MENU_PLUGIN },
  { label: 'Patient health data', pluginName: PATIENT_HEALTH_DATA_MENU_PLUGIN },
  { label: 'Appointment', pluginName: APPOINTMENT_MENU_PLUGIN },
  { label: 'Practitioner', pluginName: PRACTITIONER_MENU_PLUGIN },
  { label: 'Location', pluginName: LOCATION_MENU_PLUGIN },
  { label: 'General', pluginName: GENERAL_MENU_PLUGIN }
]

export const practitionerMenuButtons = [
  { label: 'Letter practitioner', pluginName: LETTER_PRACTITIONER_MENU_PLUGIN },
  { label: 'Referring practitioner', pluginName: REFERRING_PRACTITIONER_MENU_PLUGIN },
  { label: 'Family practitioner', pluginName: FAMILY_PRACTITIONER_MENU_PLUGIN }
]