import React from "react";

interface ErrorBoundaryProps {
  fallback: React.ReactNode,
  children: React.ReactNode
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    console.error(error)
    console.error(info.componentStack)
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // @ts-ignore
      return this.props.fallback
    }

    // @ts-ignore
    return this.props.children
  }
}

export default ErrorBoundary