import React from "react"
import {
  FieldValues,
  UseFormProps as UseReactFormProps,
  UseFormReturn,
  useForm as useReactForm,
  FieldError
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

//export everything from react-hook-form so that we don't have to split imports between react-hook-form and this file
export * from "react-hook-form"

export interface UseFormProps<
    TFieldValues extends FieldValues
  > extends Omit<UseReactFormProps<
    TFieldValues
  >, 'resolver'> {
  schema?: yup.ObjectSchema<TFieldValues> | ReturnType<any>
  requiredSchema?: yup.ObjectSchema<TFieldValues> | ReturnType<any>
  context?: any
}

export function useForm<
    TFieldValues extends FieldValues = FieldValues
  >
({
    schema,
    requiredSchema,
    context,
    ...props
  }:UseFormProps<TFieldValues>
): UseFormReturn<TFieldValues> {
  return useReactForm<TFieldValues, any>({
    ...props,
    context: {
      ...context,
      schema: schema,
      requiredSchema: requiredSchema ?? schema
    },
    ...(!!schema) && {resolver: yupResolver<yup.InferType<typeof schema>>(schema)}
  })
}

export const Form = React.forwardRef((props:any, ref?: React.ForwardedRef<any> ) => {
  const { children, onSubmit, ...rest } = props
  return (
    <form
      ref={ref}
      noValidate={true}
      onSubmit={(event) => {
        event.stopPropagation()
        onSubmit(event)
      }}
      {...rest}
    >
      {children}
    </form>
  )
})

export const getFieldErrorMessages = (fieldError?: FieldError) : string[] => {
  if (Array.isArray(fieldError)) {
    return fieldError.reduce((fieldErrors: string[], error: FieldError) => {
      fieldErrors.push(...getFieldErrorMessages((error)))
      return fieldErrors
    }, [])
  } else {
    return fieldError?.message ? [fieldError.message] : []
  }
}

export const getFirstFieldErrorMessage = (fieldError?: FieldError) : string => {
  return getFieldErrorMessages(fieldError)?.[0]
}